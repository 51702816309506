var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vehicle-edit-form" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "left",
          },
        },
        [
          _c("h3", [_vm._v("基本信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "车牌号：", prop: "licensePlateNumber" },
                    },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          "fetch-suggestions": _vm.querySearchAsync,
                          clearable: "",
                          placeholder: "请输入车牌号",
                        },
                        on: { select: _vm.handleSelect },
                        model: {
                          value: _vm.addForm.licensePlateNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "licensePlateNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.licensePlateNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车辆名称：", prop: "brand" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车辆名称" },
                        model: {
                          value: _vm.addForm.brand,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "brand",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.brand",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属企业：", prop: "companyId" } },
                    [
                      _c("companySelect", {
                        attrs: {
                          clearable: true,
                          "company-tree": _vm.companyList,
                          valuename: _vm.valueAddName,
                          value: _vm.valueAdd,
                        },
                        on: {
                          getValue: _vm.getAddGroupId,
                          getName: _vm.getAddGroupName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车辆状态：", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择车辆状态",
                          },
                          model: {
                            value: _vm.addForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "status", $$v)
                            },
                            expression: "addForm.status",
                          },
                        },
                        _vm._l(_vm.vehicleStatusList, function (item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              value: item.dictCode,
                              label: item.dictValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "座位数：", prop: "seatNumber" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入座位数" },
                        model: {
                          value: _vm.addForm.seatNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "seatNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.seatNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车辆等级：", prop: "level" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择车辆等级",
                          },
                          on: { change: _vm.levelChange },
                          model: {
                            value: _vm.addForm.level,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "level", $$v)
                            },
                            expression: "addForm.level",
                          },
                        },
                        _vm._l(_vm.levelList, function (item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: {
                                value: item.dictCode,
                                label: item.dictValue,
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车辆颜色：", prop: "vehicleColor" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择车辆颜色",
                          },
                          on: { change: _vm.vehicleColorChange },
                          model: {
                            value: _vm.addForm.vehicleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "vehicleColor", $$v)
                            },
                            expression: "addForm.vehicleColor",
                          },
                        },
                        _vm._l(_vm.vehicleColorList, function (item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: {
                                value: item.dictCode,
                                label: item.dictValue,
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "车牌颜色：", prop: "licensePlateColor" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择车牌颜色",
                          },
                          on: { change: _vm.licensePlateColorChange },
                          model: {
                            value: _vm.addForm.licensePlateColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "licensePlateColor", $$v)
                            },
                            expression: "addForm.licensePlateColor",
                          },
                        },
                        _vm._l(_vm.licensePlateColorList, function (item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: {
                                value: item.dictCode,
                                label: item.dictValue,
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务类型：", prop: "operationMode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: "请选择业务类型",
                          },
                          on: { change: _vm.ModeListChange },
                          model: {
                            value: _vm.addForm.operationMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "operationMode", $$v)
                            },
                            expression: "addForm.operationMode",
                          },
                        },
                        _vm._l(_vm.operationModeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              label: item.label,
                              value: item.value,
                              disabled: item.disabled,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属线路：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: !_vm.getScheduleLineAssociationListFlag,
                            multiple: _vm.lineMultiple,
                            clearable: "",
                            placeholder: "请选择所属线路",
                          },
                          model: {
                            value: _vm.addForm.lineIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "lineIds", $$v)
                            },
                            expression: "addForm.lineIds",
                          },
                        },
                        _vm._l(_vm.carlineNameSelect, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "车型分类：",
                        prop: _vm.vehicleModelTypeCodeRULE,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择车型分类",
                          },
                          model: {
                            value: _vm.addForm.vehicleModelTypeCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "vehicleModelTypeCode", $$v)
                            },
                            expression: "addForm.vehicleModelTypeCode",
                          },
                        },
                        _vm._l(_vm.carTypeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.vehicleTypeChildName,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("行驶证信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "行驶证号码：", prop: "drivingNumber" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入行驶证号码",
                          maxlength: "50",
                        },
                        model: {
                          value: _vm.addForm.drivingNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "drivingNumber", $$v)
                          },
                          expression: "addForm.drivingNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期至：", prop: "drivingEnd" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.addForm.drivingEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "drivingEnd", $$v)
                          },
                          expression: "addForm.drivingEnd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "道路运输证号：",
                        prop: "transportNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入行驶证号码",
                          maxlength: "50",
                        },
                        model: {
                          value: _vm.addForm.transportNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "transportNumber", $$v)
                          },
                          expression: "addForm.transportNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期至：", prop: "transportEnd" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.addForm.transportEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "transportEnd", $$v)
                          },
                          expression: "addForm.transportEnd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("保险信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "保险生效时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.addForm.insuranceStartTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "insuranceStartTime", $$v)
                          },
                          expression: "addForm.insuranceStartTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期至：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.addForm.insuranceEndTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "insuranceEndTime", $$v)
                          },
                          expression: "addForm.insuranceEndTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "维护保养时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.addForm.maintenanceStartTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "maintenanceStartTime", $$v)
                          },
                          expression: "addForm.maintenanceStartTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期至：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.addForm.maintenanceEndTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "maintenanceEndTime", $$v)
                          },
                          expression: "addForm.maintenanceEndTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.editLoading,
              },
              on: { click: _vm.onSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }