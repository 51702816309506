<template>
  <div class="vehicle-edit-form">
    <el-form
      ref="form"
      :model="addForm"
      :rules="rules"
      label-width="100px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <h3>基本信息</h3>
      <el-row>
        <el-col :span="12">
          <el-form-item label="车牌号：" prop="licensePlateNumber">
            <el-autocomplete
              v-model.trim="addForm.licensePlateNumber"
              :fetch-suggestions="querySearchAsync"
              clearable
              placeholder="请输入车牌号"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车辆名称：" prop="brand">
            <el-input
              v-model.trim="addForm.brand"
              placeholder="请输入车辆名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属企业：" prop="companyId">
            <companySelect
              :clearable="true"
              :company-tree="companyList"
              :valuename="valueAddName"
              :value="valueAdd"
              @getValue="getAddGroupId"
              @getName="getAddGroupName"
            ></companySelect>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车辆状态：" prop="status">
            <el-select
              v-model="addForm.status"
              clearable
              placeholder="请选择车辆状态"
            >
              <el-option
                v-for="item in vehicleStatusList"
                :key="item.dictCode"
                :value="item.dictCode"
                :label="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="座位数：" prop="seatNumber">
            <el-input
              v-model.trim="addForm.seatNumber"
              placeholder="请输入座位数"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车辆等级：" prop="level">
            <el-select
              v-model="addForm.level"
              clearable
              placeholder="请选择车辆等级"
              @change="levelChange"
            >
              <el-option
                v-for="item in levelList"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="{ value: item.dictCode, label: item.dictValue }"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车辆颜色：" prop="vehicleColor">
            <el-select
              v-model="addForm.vehicleColor"
              clearable
              placeholder="请选择车辆颜色"
              @change="vehicleColorChange"
            >
              <el-option
                v-for="item in vehicleColorList"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="{ value: item.dictCode, label: item.dictValue }"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车牌颜色：" prop="licensePlateColor">
            <el-select
              v-model="addForm.licensePlateColor"
              clearable
              placeholder="请选择车牌颜色"
              @change="licensePlateColorChange"
            >
              <el-option
                v-for="item in licensePlateColorList"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="{ value: item.dictCode, label: item.dictValue }"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务类型：" prop="operationMode">
            <el-select
              v-model="addForm.operationMode"
              multiple
              placeholder="请选择业务类型"
              @change="ModeListChange"
            >
              <el-option
                v-for="item in operationModeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属线路：">
            <el-select
              v-model="addForm.lineIds"
              :disabled="!getScheduleLineAssociationListFlag"
              :multiple="lineMultiple"
              clearable
              placeholder="请选择所属线路"
            >
              <el-option
                v-for="item in carlineNameSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12"
          ><el-form-item label="车型分类：" :prop="vehicleModelTypeCodeRULE">
            <el-select
              v-model="addForm.vehicleModelTypeCode"
              clearable
              placeholder="请选择车型分类"
            >
              <el-option
                v-for="item in carTypeList"
                :key="item.id"
                :label="item.vehicleTypeChildName"
                :value="item.id"
              ></el-option>
            </el-select> </el-form-item
        ></el-col>
      </el-row>
      <h3>行驶证信息</h3>
      <el-row>
        <el-col :span="12">
          <el-form-item label="行驶证号码：" prop="drivingNumber">
            <el-input
              v-model="addForm.drivingNumber"
              placeholder="请输入行驶证号码"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="有效期至：" prop="drivingEnd">
            <el-date-picker
              v-model="addForm.drivingEnd"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="道路运输证号：" prop="transportNumber">
            <el-input
              v-model="addForm.transportNumber"
              placeholder="请输入行驶证号码"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="有效期至：" prop="transportEnd">
            <el-date-picker
              v-model="addForm.transportEnd"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <h3>保险信息</h3>
      <el-row>
        <el-col :span="12">
          <el-form-item label="保险生效时间：">
            <el-date-picker
              v-model="addForm.insuranceStartTime"
              value-format="yyyy-MM-dd"
              placeholder="请选择时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="有效期至：">
            <el-date-picker
              v-model="addForm.insuranceEndTime"
              value-format="yyyy-MM-dd"
              placeholder="请选择时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="维护保养时间：">
            <el-date-picker
              v-model="addForm.maintenanceStartTime"
              value-format="yyyy-MM-dd"
              placeholder="请选择时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="有效期至：">
            <el-date-picker
              v-model="addForm.maintenanceEndTime"
              value-format="yyyy-MM-dd"
              placeholder="请选择时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- prop="vehicleModelTypeCode" -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="default" size="small" @click="$emit('cancel')"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="small"
        :loading="editLoading"
        @click="onSave"
        >保存</el-button
      >
    </span>
  </div>
</template>

<script>
import {
  queryItsListAPI,
  scheduleLineAssociationListAPI,
  insertVehicle,
  updateVehicle,
} from "@/api/lib/api.js";
import { selectLineAPI } from "@/api/lib/customizedBus.js";
import companySelect from "@/components/treeSelect/companySelect.vue";

export default {
  props: [
    "companyList",
    "levelList",
    "vehicleStatusList",
    "vehicleColorList",
    "operationModeList",
    "licensePlateColorList",
    "carTypeList",
  ],
  components: { companySelect },
  data() {
    return {
      carlineNameSelect: [],
      lineMultiple: true,
      selectOnoff: false,
      vehicleModelTypeCodeRULE: "vehicleModelTypeCode",
      companyId: null,
      addForm: {
        companyName: "",
        companyId: [],
        brand: "",
        licensePlateNumber: "",
        operationMode: "",
        vehicleColor: "", //车辆颜色
        vehicleColorStr: "",
        seatNumber: "",
        lineIds: [],
        level: "",
        levelStr: "",
        status: "",
        statusStr: "",
        licensePlateColor: "", //车牌颜色
        licensePlateColorStr: "",
        vehicleModelTypeCode: null, // 车型类型编号
        vehicleModelTypeCodeStr: "", // 车型类型名称
        //行驶证信息
        drivingNumber: "",
        drivingEnd: "",
        transportNumber: "",
        transportEnd: "",
        takeVideoChanelNumber: "",
        insuranceStartTime: "",
        insuranceEndTime: "",
        maintenanceStartTime: "",
        maintenanceEndTime: "",
        orgCa: "",
      },
      rules: {
        brand: [{ required: true, message: "请输入必填项", trigger: "blur" }],
        status: [
          { required: true, message: "请选择车辆状态", trigger: "change" },
        ],
        licensePlateNumber: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        licensePlateColor: [
          { required: true, message: "请选择车牌颜色", trigger: "change" },
        ],
        vehicleColor: [
          { required: true, message: "请选择车辆颜色", trigger: "change" },
        ],
        seatNumber: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        level: [{ required: true, message: "请输入必填项", trigger: "blur" }],
        companyId: [
          { required: true, message: "请选择所属公司", trigger: "change" },
        ],
        operationMode: [
          { required: true, message: "请选择业务类型", trigger: "blur" },
        ],
        lineIds: [
          { required: true, message: "请选择所属线路", trigger: "blur" },
        ],
        vehicleModelTypeCode: [
          { required: true, message: "请选择车型分类", trigger: "blur" },
        ],
      },
      valueAddName: "",
      valueAdd: 0,
      editLoading: false,
      channals: [],
    };
  },
  computed: {
    getScheduleLineAssociationListFlag() {
      return (
        (this.addForm.operationMode[0] == 1 ||
          this.addForm.operationMode[0] == 7) &&
        !!this.companyId
      );
    },
    operationMode() {
      return this.addForm.operationMode.indexOf(1) != -1;
    },
  },
  watch: {
    operationMode(val) {
      if (val && this.companyId) {
        this.queryLineData();
      }
    },
    companyId(val) {
      if (val && this.operationMode) {
        this.queryLineData();
      }
    },
    "addForm.vehicleModelTypeCode"() {
      this.addForm.vehicleModelTypeCodeStr = "";
    },
  },
  methods: {
    // 回显数据
    loadInfo(info) {
      this.companyId = info?.companyId;
      this.valueAdd = null;
      this.valueAddName = "";
      this.lineMultiple = true;
      this.addForm = {
        companyName: "",
        companyId: [],
        brand: "",
        licensePlateNumber: "",
        operationMode: "",
        vehicleColor: "", //车辆颜色
        vehicleColorStr: "",
        seatNumber: "",
        lineIds: "",
        level: "",
        levelStr: "",
        status: "",
        statusStr: "",
        licensePlateColor: "", //车牌颜色
        licensePlateColorStr: "",
        drivingNumber: "",
        drivingEnd: "",
        transportNumber: "",
        transportEnd: "",
        takeVideoChanelNumber: "",
        insuranceStartTime: "",
        insuranceEndTime: "",
        maintenanceStartTime: "",
        maintenanceEndTime: "",
      };
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      this.handelRowData(info);
    },
    // 回显数据
    handelRowData(row) {
      if (!row) return;
      // Object.assign(this.addForm, row);
      this.addForm = this.deepClone(row);
      this.valueAdd = row.companyId;
      this.valueAddName = row.companyName;
      this.channals = row.takeVideoChanel ? new Array(row.takeVideoChanel) : [];
      if (row.operationMode.indexOf(7) !== -1) {
        this.addForm.lineIds = row.lineIds[0];
      }
      if (row.operationMode.length > 1) {
        this.vehicleModelTypeCodeRULE = "vehicleModelTypeCode";
      } else {
        if (row.operationMode[0] === 1 || row.operationMode[0] === 7) {
          this.vehicleModelTypeCodeRULE = "";
        } else {
          this.vehicleModelTypeCodeRULE = "vehicleModelTypeCode";
        }
      }
      //如果城际专车和村通客运都没勾选的情况
      if (
        row.operationMode.indexOf(1) == -1 &&
        row.operationMode.indexOf(7) == -1
      ) {
        this.addForm.lineIds = [];
        this.operationModeList.map((item) => {
          if (item.value == 1 || item.value == 7) {
            item.disabled = true;
          }
        });
      } else {
        if (row.operationMode.indexOf(1) !== -1) {
          this.operationModeList.map((item) => {
            if (item.value !== 1) {
              item.disabled = true;
            }
          });
          // 城际专车线路查询
          // scheduleLineAssociationListAPI({ companyId: this.companyId }).then(
          //   (res) => {
          //     if (res.code == "SUCCESS") {
          //       this.carlineNameSelect = res.data;
          //     } else {
          //       this.$message.error(res.msg);
          //     }
          //   }
          // );
        }
        // 如果是村通客运
        if (row.operationMode.indexOf(7) !== -1) {
          this.lineMultiple = false;
          this.operationModeList.map((item) => {
            if (item.value !== 7) {
              item.disabled = true;
            }
          });
          // 村通客运线路查询
          selectLineAPI().then((res) => {
            if (res.code == "SUCCESS") {
              this.carlineNameSelect = res.data;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }
      this.selectOnoff = false;
      // 等级过滤
      this.levelList.map((item) => {
        if (item.dictCode == row.level) {
          this.addForm.level = { value: item.dictCode, label: item.dictValue };
        }
      });
      // 车牌颜色过滤
      this.licensePlateColorList.map((item) => {
        if (item.dictCode == row.licensePlateColor) {
          this.addForm.licensePlateColor = {
            value: item.dictCode,
            label: item.dictValue,
          };
        }
      });
      // 车辆颜色过滤
      this.vehicleColorList.map((item) => {
        if (item.dictCode == row.vehicleColor) {
          this.addForm.vehicleColor = {
            value: item.dictCode,
            label: item.dictValue,
          };
        }
      });
      // 车辆状态过滤
      this.vehicleStatusList.map((item) => {
        if (item.dictCode == this.addForm.status) {
          this.addForm.status = item.dictCode;
        }
      });
      // 车型分类过滤
      this.carTypeList.map((item) => {
        if (item.id == this.addForm.vehicleModelTypeCode) {
          this.addForm.vehicleModelTypeCodeStr = item.vehicleTypeChildName;
        }
      });
      this.dialogVisible = true;
    },
    // 车牌颜色下拉change事件
    licensePlateColorChange(val) {
      this.addForm.licensePlateColorStr = val;
    },
    // 车辆颜色下拉change事件
    vehicleColorChange(val) {
      console.log(val);
      this.addForm.vehicleColorStr = val.label;
    },
    // 等级下拉change事件
    levelChange(val) {
      console.log(val);
      this.addForm.levelStr = val.label;
    },
    // 新增企业名字回显
    getAddGroupName(n) {
      this.addForm.companyName = n;
    },
    // 企业回显
    getAddGroupId(v, orgCa) {
      this.companyId = v;
      this.addForm.companyId = [v];
      this.addForm.orgCa = orgCa;
      if (this.selectOnoff == false) {
        this.selectOnoff = true;
      } else {
        this.addForm.lineIds = [];
      }
    },
    // 获取线路数据
    queryLineData() {
      //参数 isParticipate 默认值为false，表示不获取参营线路
      scheduleLineAssociationListAPI({ companyId: this.companyId,isParticipate:true }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.carlineNameSelect = res.data;
            //过滤所属线路显示问题
            if (this.$parent.title === "修改") {
              this.addForm.lineIds = this.addForm.lineIds.filter((element) => {
                return this.carlineNameSelect.some((item) => {
                  return item.id === element;
                });
              });
            }
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    handleSelect(item) {
      this.valueAddName = item.companyName;
      this.valueAdd = item.companyId;
      this.addForm.companyId = [item.companyId];
      this.addForm.vehicleColor = { value: item.vehicleColor };
      this.addForm.licensePlateColor = { value: item.licensePlateColor };
      this.addForm.status = item.vehicleStatus;
      this.addForm.seatNumber = item.seatNum;
      this.addForm.vehicleNo = item.vehicleNo;
      const license = item.vehicleDrivingLicense;
      if (license) {
        this.addForm.drivingNumber = license.drivingNumber;
        this.addForm.drivingEnd = license.drivingEnd;
        this.addForm.transportNumber = license.transportNumber;
        this.addForm.transportEnd = license.transportEnd;
      }
      const device = item.deviceNos?.[0];
      this.addForm.takeDeviceNo = device?.deviceNo;
      const channals = device?.videoChanel - 0;
      if (!isNaN(channals)) {
        this.addForm.takeVideoChanel = channals;
        this.channals = new Array(channals);
      }
    },
    // 车牌号模糊查询
    querySearchAsync(queryString, cb) {
      if (queryString != "" && queryString != " ") {
        let cphArray = [];
        queryItsListAPI({ cph: queryString }).then((res) => {
          console.log(res);
          if (res.code == "SUCCESS") {
            res.data.map((item) => {
              cphArray.push({ value: item.cph, ...item });
            });
            cb(cphArray);
          }
        });
      }
    },
    // 业务类型下拉框change事件
    ModeListChange(val) {
      this.lineMultiple = true;
      // 如果选择的值里面不包含城际专车何村通客运，就将所属线路禁用掉，同时要把这两项不能选择
      if (val.indexOf(1) == -1 && val.indexOf(7) == -1) {
        this.addForm.lineIds = [];
        this.operationModeList.map((item) => {
          if (item.value == 1 || item.value == 7) {
            item.disabled = true;
          }
        });
      } else {
        if (val.indexOf(1) !== -1) {
          this.operationModeList.map((item) => {
            if (item.value !== 1) {
              item.disabled = true;
            }
          });
          // 城际专车线路查询
          // scheduleLineAssociationListAPI({ companyId: this.companyId }).then(
          //   (res) => {
          //     if (res.code == "SUCCESS") {
          //       this.carlineNameSelect = res.data;
          //     } else {
          //       this.$message.error(res.msg);
          //     }
          //   }
          // );
        }
        // 如果选择的是村通客运
        if (val.indexOf(7) !== -1) {
          this.lineMultiple = false;
          this.addForm.lineIds = "";
          this.operationModeList.map((item) => {
            if (item.value !== 7) {
              item.disabled = true;
            }
          });
          // 村通客运线路查询
          selectLineAPI().then((res) => {
            if (res.code == "SUCCESS") {
              this.carlineNameSelect = res.data;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }
      if (val.length > 1) {
        this.vehicleModelTypeCodeRULE = "vehicleModelTypeCode";
      } else {
        if (val.length == 0) {
          this.operationModeList.map((item) => {
            item.disabled = false;
          });
        }
        // 规则配置校验
        if (val[0] === 1 || val[0] === 7) {
          this.vehicleModelTypeCodeRULE = "";
        } else {
          this.vehicleModelTypeCodeRULE = "vehicleModelTypeCode";
        }
      }
      this.$refs.form.clearValidate(["vehicleModelTypeCode"]);
    },
    // 新增/保存
    onSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let level = this.addForm.level;
          let licensePlateColor = this.addForm.licensePlateColor;
          let vehicleColor = this.addForm.vehicleColor;
          // 等级过滤
          this.levelList.map((item) => {
            if (item.dictCode == level.value) {
              this.addForm.level = item.dictCode;
              this.addForm.levelStr = item.dictValue;
            }
          });
          // 车牌颜色过滤
          this.licensePlateColorList.map((item) => {
            if (item.dictCode == licensePlateColor.value) {
              this.addForm.licensePlateColor = item.dictCode;
              this.addForm.licensePlateColorStr = item.dictValue;
            }
          });
          // 车辆颜色过滤
          this.vehicleColorList.map((item) => {
            if (item.dictCode == vehicleColor.value) {
              this.addForm.vehicleColor = item.dictCode;
              this.addForm.vehicleColorStr = item.dictValue;
            }
          });
          // 车辆状态过滤
          this.vehicleStatusList.map((item) => {
            if (item.dictCode == this.addForm.status) {
              this.addForm.statusStr = item.dictValue;
            }
          });
          // 车型分类过滤
          this.carTypeList.map((item) => {
            if (item.id == this.addForm.vehicleModelTypeCode) {
              this.addForm.vehicleModelTypeCodeStr = item.vehicleTypeChildName;
            }
          });
          this.editLoading = true;
          let parameterOBJ = this.deepClone(this.addForm);
          parameterOBJ.companyId = parameterOBJ.companyId[0]
            ? parameterOBJ.companyId[0]
            : parameterOBJ.companyId;
          if (!this.addForm.id) {
            insertVehicle({
              ...parameterOBJ,
            })
              .then((res) => {
                if (res.code === "SUCCESS") {
                  this.$message.success("新增成功");
                  this.$emit("saveOk");
                } else {
                  this.$message.error(res.msg);
                }
                this.editLoading = false;
              })
              .catch((err) => {
                this.editLoading = false;
              });
          } else {
            updateVehicle({
              ...parameterOBJ,
            })
              .then((res) => {
                if (res.code === "SUCCESS") {
                  this.$message.success("修改成功");
                  this.$emit("saveOk");
                } else {
                  this.$message.error(res.msg);
                }
                this.editLoading = false;
              })
              .catch((err) => {
                this.editLoading = false;
              });
          }
          this.selectOnoff = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicle-edit-form {
  .el-form {
    max-height: 60vh;
    overflow-y: auto;
    padding: 0;
    .el-row {
      padding-left: 50px;
    }
  }
  .dialog-footer {
    padding: 20px 0 0 0;
    display: block;
    text-align: right;
  }
}
</style>
