<template>
  <div ref="vehicleManagement" class="vehicle-management">
    <div ref="functionButtons" class="function-buttons">
      <el-button
        v-if="listFind('新增')"
        type="primary"
        size="small"
        @click="onAddUser"
        >新增</el-button
      >
    </div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="车辆名称：">
          <el-input
            v-model.trim="form.brand"
            placeholder="请输入车辆名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-input
            v-model.trim="form.licensePlateNumber"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="车辆等级：">
          <el-select
            v-model="form.level"
            clearable
            placeholder="请选择车辆等级"
          >
            <el-option
              v-for="role in levelList"
              :key="role.dictCode"
              :label="role.dictValue"
              :value="role.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务类型：">
          <el-select
            v-model="form.operationMode"
            multiple
            clearable
            @change="queryModelChange"
          >
            <el-option
              v-for="item in operationModeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属线路：">
          <el-select
            v-model="form.lineIds"
            :disabled="lineDisabled2"
            multiple
            clearable
            collapse-tags
            placeholder="请选择所属线路"
          >
            <el-option
              v-for="role in carlineNameSelect"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      :table-height="tableHeight"
      operation-width="110"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('编辑')"
            type="text"
            size="small"
            sort="primary"
            @click="onUpdateUser(scope.scopeRow)"
            >编辑</el-button
          >
          <el-button
            v-if="listFind('删除')"
            type="text"
            size="small"
            sort="danger"
            @click="onDelete(scope.scopeRow)"
            >删除</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <div id="qrcode" ref="qrcode"></div>
    <!-- 新增|编辑 -->
    <el-dialog
      v-if="dialogVisible"
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <vehicleEditForm
        ref="addForm"
        :companyList="companyList"
        :carTypeList="carTypeList"
        :operationModeList="operationModeList"
        :licensePlateColorList="licensePlateColorList"
        :vehicleStatusList="vehicleStatusList"
        :levelList="levelList"
        :vehicleColorList="vehicleColorList"
        @cancel="dialogVisible = false"
        @saveOk="getDataList"
      />
    </el-dialog>
  </div>
</template>

<script>
import vehicleEditForm from "./vehicleEditForm/vehicleEditForm.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
import QRCode from "qrcodejs2";
import {
  getVehicleList,
  deleteVehicle,
  getCompanyTree,
  getAllLine,
  getItsVehicleStatusListAPI,
  getItsVehicleGradeListAPI,
  getItsVehicleColorListAPI,
  getItsVehicleSignageColorListAPI,
  renderCarTypeListAPI,
} from "@/api/lib/api.js";
import { selectLineAPI } from "@/api/lib/customizedBus.js";
export default {
  name: "vehicleManagement",
  components: {
    companySelect,
    vehicleEditForm,
  },
  data() {
    return {
      lineMultiple: true,
      vehicleModelTypeCodeRULE: "vehicleModelTypeCode",
      carTypeList: [],
      lineDisabled: true,
      lineDisabled2: true,
      operationModeList: [
        {
          label: "定制客运",
          value: 1,
          disabled: false,
        },
        {
          label: "包车",
          value: 4,
          disabled: false,
        },
        {
          label: "租车",
          value: 5,
          disabled: false,
        },
        {
          label: "村通客运",
          value: 7,
          disabled: false,
        },
      ],
      titleName: [
        {
          title: "车牌号",
          props: "licensePlateNumber",
          width: 100,
        },
        {
          title: "车辆名称",
          props: "brand",
          width: 120,
        },
        {
          title: "所属企业",
          props: "companyName",
          width: 160,
        },
        {
          title: "车辆状态",
          props: "statusStr",
        },
        {
          title: "座位数",
          props: "seatNumber",
        },
        {
          title: "车辆等级",
          props: "level",
          width: 100,
          SpecialJudgment: (res) => {
            let level = "";
            this.levelList.map((item) => {
              if (item.dictCode == res) {
                level = item.dictValue;
              }
            });
            return level;
          },
        },
        {
          title: "车牌颜色",
          props: "licensePlateColorStr",
        },
        {
          title: "车辆颜色",
          props: "vehicleColor",
          SpecialJudgment: (res) => {
            let color = "";
            this.vehicleColorList.map((item) => {
              if (item.dictCode == res) {
                color = item.dictValue;
              }
            });
            return color;
          },
        },
        {
          title: "二维码",
          render: (h, params, index) => {
            return h(
              "span",
              {
                style: {
                  color: "red",
                  cursor: "pointer",
                },
                on: {
                  click: () => {
                    this.downloadQR(params.feedBackQrCode);
                  },
                },
              },
              "下载二维码"
            );
          },
        },
        {
          title: "车型分类",
          props: "vehicleModelTypeCodeStr",
        },
        {
          title: "业务类型",
          props: "operationMode",
          width: 120,
          SpecialJudgment: (res) => {
            let txt = "";
            res.map((item) => {
              this.operationModeList.map((jump) => {
                if (item == jump.value) {
                  txt += jump.label + "，";
                }
              });
            });
            return txt.substr(0, txt.length - 1);
          },
        },
        {
          title: "所属线路",
          props: "lineNames",
          width: 130,
        },
      ],
      tableHeight: 0,
      tableData: [],
      value: null,
      valuename: null,
      form: {
        brand: null,
        licensePlateNumber: null,
        companyId: null,
        level: null,
        lineIds: null,
        operationMode: null,
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      dialogVisible: false,
      title: "",
      licensePlateColorList: [], //车牌颜色
      vehicleStatusList: [], //车辆状态数据源
      levelList: [], //车辆等级
      vehicleColorList: [], // 车辆颜色
      companyList: [],
      searchLoading: false,
      editLoading: false,
      carlineNameSelect: [],
      carlineNameSelect2: [],
      selectOnoff: false,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.operationModeList = this.$options.data().operationModeList;
      }
    },
  },
  methods: {
    // 上面查询表格的业务类型下拉框change
    queryModelChange(val) {
      if (val.indexOf(1) == -1 && val.indexOf(7) == -1) {
        this.lineDisabled2 = true;
        this.form.lineIds = [];
        this.operationModeList.map((item) => {
          if (item.value == 1 || item.value == 7) {
            item.disabled = true;
          }
        });
      } else {
        this.lineDisabled2 = false;
        if (val.indexOf(1) !== -1) {
          this.operationModeList.map((item) => {
            if (item.value !== 1) {
              item.disabled = true;
            }
          });
          // 城际专车线路查询
          getAllLine().then((res) => {
            if (res.code == "SUCCESS") {
              this.carlineNameSelect = res.data;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
        if (val.indexOf(7) !== -1) {
          this.operationModeList.map((item) => {
            if (item.value !== 7) {
              item.disabled = true;
            }
          });
          // 村通客运线路查询
          selectLineAPI().then((res) => {
            if (res.code == "SUCCESS") {
              this.carlineNameSelect = res.data;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }
      if (val.length == 0) {
        this.operationModeList.map((item) => {
          item.disabled = false;
        });
      }
    },

    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.vehicleManagement.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        46;
    },
    // 点击查询
    onSearch() {
      this.searchLoading = true;
      this.form.currentPage = 1;
      this.getDataList();
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getDataList();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getDataList();
    },
    // 新增
    onAddUser() {
      this.title = "新增";
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.$refs.addForm) this.$refs.addForm.loadInfo();
      });
    },
    // 点击表格查看编辑按钮
    onUpdateUser(row) {
      this.title = "修改";
      // this.addForm = this.deepClone(row);
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.addForm?.loadInfo(row);
      });
    },

    // 删除
    onDelete(row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除 "),
          h("span", { style: "color: #059370" }, row.brand),
          h("span", null, "，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteVehicle({ id: row.id }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "删除成功!",
              });
              this.onSearch();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },

    //下载二维码
    downloadQR(e) {
      new QRCode(this.$refs["qrcode"], {
        text: e,
        width: 200,
        height: 200,
      });
      const fileName = e.split("=")[1];
      let myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/URL");
      a.download = fileName;
      a.click();
      this.$nextTick(() => {
        document.getElementById("qrcode").innerHTML = "";
      }); //清空数据
    },
    // 获取列表
    getDataList() {
      this.dialogVisible = false;
      getVehicleList({
        ...this.form,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
        this.searchLoading = false;
      });
    },
    // 企业回显
    getGroupId(v) {
      if (v == null) {
        this.form.companyIds = [];
      } else {
        this.form.companyIds = [v];
      }
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
  },
  created() {
    this.onSearch();
    this.getCompanyList();
    // 获取车辆状态数据
    getItsVehicleStatusListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.vehicleStatusList = res.data;
      }
    });
    // 获取车辆等级数据
    getItsVehicleGradeListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.levelList = res.data;
      }
    });
    // 获取车辆颜色数据
    getItsVehicleColorListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.vehicleColorList = res.data;
      }
    });
    // 获取车牌颜色数据
    getItsVehicleSignageColorListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.licensePlateColorList = res.data;
      }
    });
  },
  mounted() {
    // 车型列表
    renderCarTypeListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.carTypeList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    });

    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.Table {
  margin-top: 16px;
}
.vehicle-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.theme-project-gps {
  .vehicle-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.el-select {
  z-index: 1;
}
</style>
